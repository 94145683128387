import React from 'react';
import { FormattedMessage, convertLabel } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';

import css from './ListingPage.module.css';
import config from '../../config';
import { useIntl } from 'react-intl';

const SectionFeaturesMaybe = props => {
  const { publicData } = props;
  if (!publicData) {
    return null;
  }
  const intl = useIntl();
  const configAmenties = publicData?.amenities?.map(item =>
    convertLabel(config.custom.amenitiesOptions, intl)?.find(item2 => item2.key === item)
  );

  const selectedOptions = publicData && publicData.amenities ? publicData.amenities : [];
  return publicData?.amenities ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.featuresTitle" />
      </h2>
      <PropertyGroup
        id="ListingPage.amenities"
        options={configAmenties}
        selectedOptions={selectedOptions}
        twoColumns={true}
      />
    </div>
  ) : null;
};

export default SectionFeaturesMaybe;
