import React from 'react';
import { FormattedMessage, convertLabel } from '../../util/reactIntl';
import css from './ListingPage.module.css';
import config from '../../config';
import { useIntl } from 'react-intl';
const SectionDetailsMaybe = props => {
  const { publicData = null } = props;
  if (!publicData) {
    return null;
  }
  const {
    bathrooms,
    bedrooms,
    beds,
    guests,
    privateBathrooms,
    persons,
    activities,
    type,
  } = publicData;
  const intl = useIntl();
  //   const subCategoryLabel = intl
  //     .formatMessage({
  //       id:
  //         config.custom[`${category}SubCategory`]?.find(cat => cat.key === subcategory)?.label ?? '',
  //     })
  //     ?.toLowerCase();
  //   const subSubCategoryLabel = intl
  //     .formatMessage({
  //       id:
  //         config.custom[`${subcategory}SubSubCategory`]?.find(cat => cat.key === subSubCategory)
  //           ?.label ?? '',
  //     })
  //     ?.toLowerCase();
  const sectionType = type ? (
    <div>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.typeTitle" />
      </h2>
      <p className={css.description}>
        {
          convertLabel(config.custom.TypeOfAcommodationOptions, intl).find(
            item => item.key === type
          )?.label
        }
      </p>
      <br />
    </div>
  ) : null;
  return bathrooms || bedrooms || beds || guests || privateBathrooms ? (
    <div className={css.sectionDetails}>
      {sectionType}
      <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.detailsTitle" />
      </h2>

      <ul className={css.details}>
        {guests ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              {<FormattedMessage id="ListingPage.guestsTitle" />}
            </span>
            <span className={css.category}>{guests}</span>
          </li>
        ) : null}
        {persons ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.SectionDetailsMaybe.personsTitle" />
            </span>
            <span className={css.category}>{persons}</span>
          </li>
        ) : null}
        {bathrooms ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              {<FormattedMessage id="ListingPage.bathroomsTitle" />}
            </span>
            <span className={css.category}>{bathrooms}</span>
          </li>
        ) : null}
        {bedrooms ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              {<FormattedMessage id="ListingPage.bedroomsTitle" />}
            </span>
            <span className={css.category}>{bedrooms}</span>
          </li>
        ) : null}
        {beds ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              {<FormattedMessage id="ListingPage.bedsTitle" />}
            </span>
            <span className={css.category}>{beds}</span>
          </li>
        ) : null}
        {privateBathrooms ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              {<FormattedMessage id="ListingPage.privateBathroomsTitle" />}
            </span>
            <span className={css.category}>
              {
                convertLabel(config.custom.bedroomOptions, intl).find(
                  bathroom => bathroom.key === privateBathrooms
                )?.label
              }
            </span>
          </li>
        ) : null}
      </ul>
      {/* {activities?.length ? (
        <>
          <h2 className={css.featuresTitle} style={{ marginTop: '1rem' }}>
            {<FormattedMessage id="ListingPage.activitiesTitle" />}
          </h2>
          <div className={css.activitiesContainer}>
            {activities?.map((activity, index) => (
              <div key={index} className={css.activity}>
                {activity}
              </div>
            ))}
          </div>
        </>
      ) : null} */}
    </div>
  ) : null;
};

export default SectionDetailsMaybe;
