import React from 'react';
import { FormattedMessage, convertLabel } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';
import { BsCheckLg } from 'react-icons/bs';
import { FaDotCircle } from 'react-icons/fa';
import css from './ListingPage.module.css';
import config from '../../config';
import { useIntl } from 'react-intl';
function SectionPublicData(props) {
  const { publicData } = props;
  if (!publicData) {
    return null;
  }
  const {
    service,
    languages,
    level = [],
    stay,
    type,
    moreLanguage = [],
    moreLanguageLevel,
    qualifiedTeacher,
    lessons,
    private_selected,
    customOption,
    languages_spoken,
    languages_taught,
    otherQualification,
    qualification,
    customOptionList,
    otherCustomOption,
    teachingAvailability,
  } = publicData ?? {};
  const intl = useIntl();
  let note = '';
  switch (private_selected) {
    case 'one_room':
      note = intl.formatMessage({ id: 'ListingPage.SectionPublicData.noteOneRoom' });
      break;
    case 'more_than_one_place':
      note = 'Test text.';
      break;
    case 'more_than_one_place_may':
      note = 'Test text';
      break;
  }
  const languageSpokenOptions = languages_spoken?.map(item => ({
    language: convertLabel(config.custom.languageOptions, intl).find(
      item1 => item1.key === item.language
    ),
    level: convertLabel(config.custom.levelOptions, intl).find(item1 => item1.key === item.level),
  }));
  const languageTaughtOptions = languages_taught?.map(item => ({
    language: convertLabel(config.custom.languageOptions, intl).find(
      item1 => item1.key === item.language
    ),
    level: convertLabel(config.custom.levelOptions, intl).find(item1 => item1.key === item.level),
  }));
  const serviceOptions = service?.map(item =>
    convertLabel(config.custom.serviceOptions, intl).find(item2 => item2.key === item)
  );
  // console.log({ publicData });
  const sectionServices = service?.length ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.serviceTitle" />
      </h2>
      <PropertyGroup
        id="ListingPage.service"
        options={serviceOptions}
        selectedOptions={service}
        twoColumns={true}
      />
    </div>
  ) : null;
  const languageOptions = convertLabel(config.custom.languageOptions, intl).find(
    item2 => item2.key === languages
  );

  const sectionLanguages = Array.isArray(languages) ? null : languages ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.languageTitle" />
      </h2>
      {/* <PropertyGroup
        id="ListingPage.language"
        options={languageOptions}
        selectedOptions={languages}
        twoColumns={true}
      /> */}
      <p className={css.description}>{languageOptions?.label}</p>
    </div>
  ) : null;
  const moreLanguageOptions = moreLanguage?.map(i =>
    convertLabel(config.custom.languageOptions, intl)?.find(k => k.key === i)
  );
  const sectionMoreLanguages = moreLanguage?.length ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.moreLanguageTitle" />
      </h2>
      {moreLanguageOptions?.map(language => (
        <>
          <p className={css.moreLanguageContainer}>
            <FaDotCircle className={css.checkMark} />
            <span className={css.moreLanguage}>{language?.label}</span>
          </p>
          <div className={css.levelContainer}>
            <p className={css.levelTitle}>
              <FormattedMessage id="ListingPage.levelTitle" />
            </p>
            <PropertyGroup
              className={css.level}
              id="ListingPage.more"
              options={moreLanguageLevel[language?.key + 'Level']?.map(item =>
                convertLabel(config.custom.levelOptions, intl).find(k => k.key === item)
              )}
              selectedOptions={moreLanguageLevel[language?.key + 'Level']}
              twoColumns={true}
            />
          </div>
        </>
      ))}

      {/* <p className={css.description}>{languageOptions?.label}</p> */}
    </div>
  ) : null;
  const levelOptions = level?.map(i =>
    convertLabel(config.custom.levelOptions, intl).find(item => item.key === i)
  );
  const sectionPrivate = private_selected ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.SectionPublicData.typeOfStayLabel" />
      </h2>
      <p className={css.description}>
        {
          convertLabel(config.custom.privateSelectedOptions, intl).find(
            item => item.key === publicData.private_selected
          )?.label
        }
        <span className={css.note}>{note}</span>
      </p>{' '}
    </div>
  ) : null;
  const sectionLevel = level?.length ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.levelTitle" />
      </h2>
      <PropertyGroup
        id="ListingPage.level"
        options={levelOptions}
        selectedOptions={level}
        twoColumns={true}
      />
      {/* <p className={css.description}>
        {config.custom.levelOptions.find(item => item.key === level)?.label}
      </p> */}
    </div>
  ) : null;
  const moreLanguageLevelOptions =
    Array.isArray(moreLanguageLevel) &&
    moreLanguageLevel?.map(k =>
      convertLabel(config.custom.levelOptions, intl).find(s => s.key === k)
    );
  const sectionMoreLevel = moreLanguageLevel?.length ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.levelMoreTitle" />
      </h2>
      <PropertyGroup
        id="ListingPage.more"
        options={moreLanguageLevelOptions}
        selectedOptions={moreLanguageLevel}
        twoColumns={true}
      />
      {/* <p className={css.description}>
        {config.custom.levelOptions.find(item => item.key === level)?.label}
      </p> */}
    </div>
  ) : null;
  const teachingAvailabilityOptions = teachingAvailability?.map(i =>
    convertLabel(config.custom.teachingAvailability, intl).find(item => item.key === i)
  );
  const sectionTeachingAvailability = teachingAvailability?.length ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.SectionPublicData.teachingAvailability" />
      </h2>
      <PropertyGroup
        id="ListingPage.Availability"
        options={teachingAvailabilityOptions}
        selectedOptions={teachingAvailability}
        twoColumns={true}
      />
      {/* <p className={css.description}>
        {config.custom.levelOptions.find(item => item.key === level)?.label}
      </p> */}
    </div>
  ) : null;
  const sectionStay = stay ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.stayTitle" />
      </h2>
      <p className={css.description}>
        {convertLabel(config.custom.stayOptions, intl).find(item => item.key === stay)?.label}
      </p>
    </div>
  ) : null;
  const sectionType = type ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.typeTitle" />
      </h2>
      <p className={css.description}>
        {
          convertLabel(config.custom.TypeOfAcommodationOptions, intl).find(
            item => item.key === type
          )?.label
        }
      </p>
    </div>
  ) : null;

  const isOtherQualificationSelected = qualification?.find(item => item === 'other');
  const qualificationOptions = qualification
    ?.filter(k => k !== 'other')
    ?.map(i => convertLabel(config.custom.qualificationOptions, intl).find(item => item.key === i));
  const finalQualificationOptions = isOtherQualificationSelected
    ? [...qualificationOptions, ...otherQualification?.map(s => ({ label: s, key: s, value: s }))]
    : qualificationOptions;
  // console.log('finalQualificationOptions', finalQualificationOptions);
  const sectionQualifiedTeacher =
    qualifiedTeacher == 'yes' ? (
      <div className={css.sectionDescription}>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.qualifiedTeacherTitle" />
        </h2>
        <ul className={css.description}>
          {finalQualificationOptions?.map(item => <li className={css.li}>{item?.label}</li>)}
        </ul>
      </div>
    ) : null;
  const sectionLessons = lessons ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.lessonsTitle" />
      </h2>
      <p className={css.description}>
        {/* {config.custom.qualifiedTeacherOptions.find(item => item.key === qualifiedTeacher)?.label} */}
        {lessons}
      </p>
    </div>
  ) : null;

  const isOtherCustomOptionSelected = customOptionList?.find(item => item === 'other');
  const customOptions = customOptionList
    ?.filter(k => k !== 'other')
    ?.map(i => convertLabel(config.custom.customProgramOptions, intl).find(item => item.key === i));
  const finalCustomOptions = isOtherCustomOptionSelected
    ? [...customOptions, ...otherCustomOption?.map(s => ({ label: s, key: s, value: s }))]
    : customOptions;
  const sectionCustom =
    customOption == 'yes' ? (
      <div className={css.sectionDescription}>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.customTitle" />
        </h2>
        <ul className={css.description}>
          {finalCustomOptions?.map(item => <li className={css.li}>{item?.label}</li>)}
          {/* {config.custom.customOptions.find(item => item.key === customOption)?.label} */}
        </ul>
      </div>
    ) : null;
  const sectionsLanguagesWithLevel = languages_spoken?.length ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.SectionPublicData.languageSpoken" />
      </h2>
      {/* <p className={css.description}> */}
      <ul className={css.description}>
        {languageSpokenOptions?.map((language, index) => (
          <li className={css.li}>
            {language?.language?.label} - {language?.level?.label}
          </li>
        ))}
      </ul>
      {/* </p> */}
    </div>
  ) : null;
  const sectionsLanguagesTaughtWithLevel = languages_taught?.length ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.SectionPublicData.languageTaught" />
      </h2>
      {/* <p className={css.description}> */}
      <ul className={css.description}>
        {languageTaughtOptions?.map((language, index) => (
          <li className={css.li}>
            {language?.language?.label} - {language?.level?.label}
          </li>
        ))}
      </ul>
      {/* </p> */}
    </div>
  ) : null;
  return (
    <div>
      {sectionServices}
      {sectionLanguages}
      {/* {sectionLevel} */}
      {sectionMoreLanguages}
      {sectionMoreLevel}
      {sectionStay}
      {/* {sectionType} */}
      {/* {sectionPrivate} */}
      {/* {sectionsLanguagesWithLevel} */}
      {/* {sectionsLanguagesTaughtWithLevel} */}
      {sectionQualifiedTeacher}
      {/* {sectionLessons} */}
      {/* {sectionCustom} */}
      {sectionTeachingAvailability}
    </div>
  );
}

export default SectionPublicData;
