import React from 'react';
import css from './ListingPage.module.css';
import { useIntl } from 'react-intl';
import { richText } from '../../util/richText';
import { PRIVATE_TOUR } from '../../components/EditListingWizard/EditListingWizardTab';
const SectionTourMayBe = ({ publicData, title, description, isStay, language }) => {
  if (!publicData) return null;
  const {
    es_subtitle,
    subtitle,
    es_title,
    es_description,
    program,
    es_program,
    transportation,
    es_transportation,
    included = [],
    notIncluded = [],
    extra = [],
    es_meetingPoint,
    meetingPoint,
    duration,
    category,
  } = publicData;
  const intl = useIntl();
  const isLanguageEnglish = language === 'english';
  const isProgram = category === PRIVATE_TOUR;
  if (isStay) return null;
  return (
    <div className={css.mainContainer}>
      {title ? <h1 className={css.tourTitle}>{title}</h1> : null}
      <div className={css.divider} />
      {subtitle || es_subtitle ? (
        <h2 className={css.tourSubtitle}>
          {richText(isLanguageEnglish ? subtitle : es_subtitle, {
            longWordMinLength: 20,
            longWordClass: css.longWord,
          })}
        </h2>
      ) : null}
      <div className={css.divider} />
      {description ? (
        <p className={css.programDescription}>
          {richText(description, {
            longWordMinLength: 20,
            longWordClass: css.longWord,
          })}
        </p>
      ) : null}
      {program ? (
        <div>
          <h1 className={css.program}>
            {intl.formatMessage({ id: 'ListingPage.SectionTourMayBe.programLabel' })}
          </h1>
          <div className={css.blackDivider} />
          <p className={css.programDescription}>
            {richText(isLanguageEnglish ? program : es_program, {
              longWordMinLength: 20,
              longWordClass: css.longWord,
            })}
          </p>
        </div>
      ) : null}
      {duration ? (
        <div>
          <h1 className={css.program}>
            {intl.formatMessage({
              id: isProgram
                ? 'ListingPage.SectionTourMayBe.durationProgramOptions'
                : 'ListingPage.SectionTourMayBe.durationMeetingOptions',
            })}
          </h1>
          <div className={css.blackDivider} />
          {duration ? (
            <p className={css.programDescription}>
              <b>{intl.formatMessage({ id: 'ListingPage.SectionTourMayBe.duration' })} </b>
              {duration}{' '}
              {isProgram
                ? intl.formatMessage({
                    id:
                      duration > 2
                        ? 'BookingDatesForm.BookingDatesForm.days'
                        : 'BookingDatesForm.BookingDatesForm.day',
                  })
                : intl.formatMessage({
                    id:
                      duration > 2
                        ? 'BookingDatesForm.BookingDatesForm.hours'
                        : 'BookingDatesForm.BookingDatesForm.hour',
                  })}
            </p>
          ) : null}
        </div>
      ) : null}
      {transportation ? (
        <div>
          <h1 className={css.program}>
            {intl.formatMessage({ id: 'ListingPage.SectionTourMayBe.durationAndTransportation' })}
          </h1>
          <div className={css.blackDivider} />
          {transportation ? (
            <p className={css.programDescription}>
              <b>{intl.formatMessage({ id: 'ListingPage.SectionTourMayBe.transportation' })} </b>
              {isLanguageEnglish ? transportation : es_transportation}
            </p>
          ) : null}
        </div>
      ) : null}
      {included?.length ? (
        <div className={css.included}>
          <h1 className={css.program}>
            {intl.formatMessage({ id: 'ListingPage.SectionTourMayBe.whatIncluded' })}
          </h1>
          <div className={css.blackDivider} />
          {included.map((item, index) => (
            <div className={css.container} key={index}>
              <div className={css.dot}>&#8226;</div>
              <p className={css.includeItem}>{isLanguageEnglish ? item.name : item?.es_name}</p>
            </div>
          ))}
        </div>
      ) : null}
      {notIncluded?.length ? (
        <div className={css.included}>
          <h1 className={css.program}>
            {intl.formatMessage({ id: 'ListingPage.SectionTourMayBe.whatNotIncluded' })}
          </h1>
          <div className={css.blackDivider} />
          {notIncluded.map((item, index) => (
            <div className={css.container} key={index}>
              <div className={css.dot}>&#8226;</div>
              <p className={css.includeItem}>{isLanguageEnglish ? item?.name : item?.es_name}</p>
            </div>
          ))}
        </div>
      ) : null}
      {extra?.length ? (
        <div className={css.included}>
          <h1 className={css.program}>
            {intl.formatMessage({ id: 'ListingPage.SectionTourMayBe.whatsExtra' })}
          </h1>
          <div className={css.blackDivider} />
          {extra.map((item, index) => (
            <div className={css.container} key={index}>
              <div className={css.dot}>&#8226;</div>
              <p className={css.includeItem}>{isLanguageEnglish ? item.name : item?.es_name}</p>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default SectionTourMayBe;
