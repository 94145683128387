import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton } from '../../components';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import config from '../../config';

import css from './ListingPage.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { ACTIVITY, PRIVATE_TOUR } from '../../components/EditListingWizard/EditListingWizardTab';
import { getTotalWithCommission } from '../../components/BookingBreakdown/LineItemRefundMaybe';
const { Money } = sdkTypes;
const SectionHeading = props => {
  const {
    priceTitle,
    formattedPrice,
    richTitle,

    hostLink,
    showContactUser,
    onContactUser,
    publicData,
    intl,
  } = props;
  if (!publicData) return null;
  const { week_price, month_price, price_original, priceType, category } = publicData || {};
  const isFixedPrice = priceType === 'fixed_price';
  const pricePerPerson = publicData?.pricePerPerson
    ? publicData?.pricePerPerson?.sort((a, b) => a.price - b.price)?.filter(item => item.price > 0)
    : [];
  let priceRange = null;
  if (pricePerPerson?.length > 1 && !isFixedPrice) {
    priceRange =
      formatMoney(intl, new Money(pricePerPerson[0]?.price, config.currency)) +
      ' - ' +
      formatMoney(
        intl,
        new Money(pricePerPerson[pricePerPerson.length - 1]?.price, config.currency)
      );
  }
  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const weekPrice = week_price ? new Money(week_price, config.currency) : null;
  const monthPrice = month_price ? new Money(month_price, config.currency) : null;
  const weekPriceLabel = weekPrice ? formatMoney(intl, weekPrice) : null;
  const monthPriceLabel = monthPrice ? formatMoney(intl, monthPrice) : null;
  const isPrivateOrActivity =
    publicData?.category == PRIVATE_TOUR || publicData?.category == ACTIVITY;
  const unitTranslationKey = isNightly
    ? 'ListingPage.perNight'
    : isDaily
    ? 'ListingPage.perDay'
    : 'ListingPage.perUnit';

  return (
    <div className={css.sectionHeading}>
      {isPrivateOrActivity ? (
        <div className={css.activityPrice}>
          <div className={css.heading}>
            <h1 className={css.title}>{richTitle}</h1>
          </div>

          <div className={css.priceRange}>
            <div className={css.desktopPriceValue} title={priceTitle}>
              {priceRange || formattedPrice}
            </div>
            <div className={css.desktopPerUnit}>
              {category === PRIVATE_TOUR && isFixedPrice ? (
                <FormattedMessage id="BookingPanel.BookingPanel.perProgram" />
              ) : category === ACTIVITY && isFixedPrice ? (
                <FormattedMessage id="BookingPanel.BookingPanel.perActivity" />
              ) : (
                <FormattedMessage id="ListingPage.SectionHeading.perperson" />
              )}
              {/* <FormattedMessage id="ListingPage.SectionHeading.perperson" /> */}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className={css.stayPriceContainer}>
            <div className={css.pricePerNight}>
              <div className={css.stayPriceValue} title={priceTitle}>
                {formattedPrice}
              </div>
              <div className={css.perUnit}>
                {isPrivateOrActivity ? (
                  <FormattedMessage id="ListingPage.SectionHeading.perperson" />
                ) : (
                  <FormattedMessage id="ListingPage.SectionHeading.pernight" />
                )}
              </div>
            </div>
            {weekPriceLabel && weekPrice ? (
              <div className={css.pricePerWeek}>
                <div className={css.stayPriceValueWeek} title={priceTitle}>
                  {weekPriceLabel}
                </div>
                <div className={css.perUnitWeek}>
                  <FormattedMessage id="ListingPage.SectionHeading.perweek" />
                </div>
              </div>
            ) : null}
            {monthPriceLabel && monthPrice ? (
              <div className={css.pricePerMonth}>
                <div className={css.stayPriceValueWeek} title={priceTitle}>
                  {monthPriceLabel}
                </div>
                <div className={css.perUnitMonth}>
                  <FormattedMessage id="ListingPage.SectionHeading.permonth" />
                </div>
              </div>
            ) : null}
          </div>
          <div className={css.heading}>
            <h1 className={css.title}>{richTitle}</h1>
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionHeading;
